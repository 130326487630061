import { axios } from '@/utils/request'
import { baseIp } from '@/config/appSetting'

export function getAboutCourses (parameter) {
    return axios({
        url: baseIp+'AboutCourse/AboutCourses',
        method: 'get',
        params: parameter
    })
}
export function getAboutHonors (parameter) {
    return axios({
        url: baseIp+'AboutHonor/AboutHonors',
        method: 'get',
        params: parameter
    })
}
  