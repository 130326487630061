<template>
  <div class="m-about">
    <bannerStatic :num="1" />
    <div class="des-box">
       <h4 class="titleStyle">公司介绍</h4>
       <div class="content-box">
           郑州郑大智能科技股份有限公司（简称郑大智能）成立于2016年3月4日，注册资本1000万元，位于郑州市高新技术产业开发区。公司紧盯精密传动系统及半导体材料行业，将自身智能化技术与行业工艺技术紧密融合，面向行业提供更符合行业真实需求、更具有行业应用性的智能工厂整体解决方案，公司以行业专家系统及大数据为核心，形成了成熟的精密传动系统、半导体材料行业的智能制造系统集成及关键软硬件产品。公司充分依托河南省智能制造研究院、郑州机械研究所等企业和科研机构，主要成员包括来自郑州大学、北京科技大学、郑州机械研究所等知名学府和相关高新技术研发机构的专家人才，形成了具有自主研发能力和核心技术积累的精英技术团队
       </div>
    </div>
    <div class="center-pic">
      <div class="img-box">
         <img src="@/assets/img/about-center.png" class="right-img" alt="">
          <div class="txt">
            <p>
              <span>智能制造</span><br/>
              <b>—— 跨入精益管理新时代</b>
            </p>
          </div>
      </div>
       <div class="linear"> </div>
    </div>
    <div class="time-line">
       <img src="../../../assets/img/about-time.png" class="timeImg" />
        <a-timeline style="">
          <a-timeline-item v-for="(item,index) in experienceList" :key="index"  v-show="index<showNum" :class="{'orange':!item.content}" :color="`#d4d4d4`">
              <span><span >{{item.time}}</span><br/>{{item.content}}</span>
          </a-timeline-item>
        </a-timeline>
        <span class="start" v-show="btnShow" @click="lookmore()">了解更多</span>
    </div>
    <div class="honor">
      <h4 class="titleStyle">公司荣誉</h4>
       <a-row type="flex" :gutter="32"  justify="space-between">
        <a-col :span="12" v-for="(item, index) in honorList" :key="`honor-${index}`">
          <div class="img-box">
            <img class="item" :data-src="newsImg + item.aboutHonorImageUrl" alt="" srcset="">
          </div>
            <p>{{ item.aboutHonorName }}</p>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import bannerStatic from'@/components/BannerStatic/mBannerStatic'
import { getAboutCourses, getAboutHonors } from '@/api/about'
import { newsImg } from '@/config/appSetting'
import moment from 'moment'
export default {
  name: 'About',
  components:{
    bannerStatic
  },
  data() {
    return{
      showNum:5,
      btnShow:true,
      experienceList: [],
      honorList: [],
      newsImg: newsImg
    }
  },
  methods: {
    moment,
     getTop (e) {
      let T = e.offsetTop;
      while(e = e.offsetParent) {
        T += e.offsetTop;
      }
      return T;
    },
    lazyLoad (imgs) {
      var H = document.documentElement.clientHeight;
      var S = document.documentElement.scrollTop || document.body.scrollTop;
      for (var i = 0; i < imgs.length; i++) {
        if (H + S > this.getTop(imgs[i])) {
          imgs[i].src = imgs[i].getAttribute('data-src');
        }
      }
    },
    lookmore(){
      this.showNum+=6
      if(this.showNum>this.experienceList.length||this.showNum==this.experienceList.length){ this.btnShow=false}
    },
  },
  mounted () {
    const _this = this
    this.experienceList=[]
    getAboutCourses().then(res => {
      let add=[]
      if (res.state) {
        res.data.map((item,index)=>{
          let year= _this.moment(item.courseTime,'YYYY/MM/DD').format('YYYY')
          let time= _this.moment(item.courseTime,'YYYY/MM/DD').format('YYYY年M月')
          let islast = index>0&&_this.experienceList[index-1].year==year?false:true
          if(islast){ add.push(index)}
          _this.experienceList.push(
            {
              year:year,
              time:time,
              content:item.courseContent,
            }
          )
        })
        add.map((item,index)=>{
         _this.experienceList.splice(item+index,0,{time:_this.experienceList[item+index].year,content:""})
        })
      }else{
         _this.experienceList=[]
      }
    }).then(()=>{
       getAboutHonors().then(res => {
        if (res.state) {
          this.honorList = res.data ? res.data : []
        }
      })
    })
    window.onscroll = function() {
      let imgs = document.querySelectorAll('.item');
      _this.lazyLoad(imgs)
    }
  }
}
</script>

<style lang="less" scoped>
.m-about{
  overflow: hidden;
  .titleStyle {
    font-size: .48rem /* 36/75 */;
  }
  .titleStyle:after {
    content: '';
    width: .8rem /* 60/75 */;
    height: 3px;
    background: #f9a825;
    display: block;
    margin: .266667rem /* 20/75 */ auto .533333rem /* 40/75 */;
  }
  .des-box{
    background: #fff;
    padding-top:1.066667rem /* 80/75 */;
    padding-bottom: 1.333333rem /* 100/75 */;
    .content-box{
      text-align: left;
      padding:0 .666667rem /* 50/75 */ .4rem /* 30/75 */;
      font-size: .373333rem /* 28/75 */;
      line-height: 2;
    }
  }
   .center-pic{
      height: 2.813333rem /* 211/75 */;
      background-color: #1b77e3;
      position:relative;
     .linear{
       position: absolute;
       top:0;
       right: 0;
       width:50%;
       height: 100%;
       background-image: linear-gradient(266deg, #4a90e2 0%, transparent 100%);
      }
     .img-box{
       position:relative;
       .right-img{
          float: left;
          width: 43%;
          margin-top:-.773333rem /* 58/75 */;
       }
       .txt {
         position: absolute;
         text-align: left;
         color: #fff;
         font-size: .4rem /* 30/75 */;
         background:url(../../../assets/img/bout-center-line.png) no-repeat;
         background-position: 100% bottom;
         background-size: 90%;
         top: .666667rem /* 50/75 */;
         right: .4rem /* 30/75 */;
         z-index: 2;
         b{
          font-size: .4rem /* 30/75 */;
          font-weight: normal;
          display: block;
          line-height:2;
         }
        }
     }
   }
   .time-line{
     text-align: left;
     padding:.4rem /* 30/75 */;
     background: #fff;
     img{
       width: .866667rem /* 65/75 */;
       max-width: 65px;
       margin:.666667rem /* 50/75 */ 0;
     }
    .ant-timeline {
      padding-left: .266667rem /* 20/75 */;
      .ant-timeline-item{
       font-size:.373333rem /* 28/75 */;
       color:#333;
       line-height:2;
       font-weight: 500;
       &.orange{
         color:#f9a825;
       }
      }
      
     }
    .start{
      width:2.933333rem /* 220/75 */;
      height: .8rem /* 60/75 */;
      background-color: #3585e4;
      border-radius: 3px;
      font-size: .32rem /* 24/75 */;
      color:#fff;
      display: block;
      text-align: center;
      line-height:.8rem /* 60/75 */;
      margin:.666667rem /* 50/75 */ auto;
      font-weight: 400;
     }

     
   }
   .honor{
     padding:.4rem /* 30/75 */;
     .titleStyle{
       margin: .666667rem /* 50/75 */ 0;
     }
     .img-box{
       background: #fff;
       height:3.733333rem /* 280/75 */; 
       line-height:3.733333rem /* 280/75 */; 
       box-shadow: 0 2px 12px 0 hsla(0, 0%, 89%, 0.69);
       .item {
          max-width: 80%;
          max-height: 80%;
        }
     }
     p {
        text-align: center;
        font-size: .32rem /* 24/75 */;
        height:1.04rem /* 78/75 */;
        margin: .266667rem /* 20/75 */;
        overflow: hidden;
    }
   }
}

</style>
<style>
.m-about .ant-timeline-item-content {
  position: relative;
  top: -.32rem /* 24/75 */ !important;
  margin: 0 0 0 .666667rem /* 50/75 */ !important;
}
.m-about .ant-timeline-item-head {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border: 2px solid transparent;
    top: 0px;
    left: 0px;
}
.m-about .ant-timeline-item-tail{
  top:0
}
</style>
